export const productsResponseParse = (response: DBProduct[]): Product[] => {
  return response.map(product => ({
    id: product.id,
    name: product.name,
    code: product.code,
    unit: product.unit,
    unitName: product.unit_name,
    price: product.price,
    currencyCode: product.currency_code,
    sku: product.sku,
    stock: product.stock,
    stock_alert_quantity: product.stock_alert_quantity,
    cost: product.cost,
    brand: product.brand,
    available: product.available,
    pictureUrl: product.picture_url,
    supplier: product.supplier,
    supplierId: product.supplier_id,
    productCategory: product.product_category,
    productCategoryId: product.product_category_id,
    allergens: product.allergens,
    favoriteProducts: product.favorite_products,
    replacementProducts: product.replacement_products,
    seasonalityStartDate: product.seasonality_start_date,
    seasonalityEndDate: product.seasonality_end_date,
    orderLines: product.order_lines,
    uploadedByCsvAt: product.uploaded_by_csv_at,
    createdAt: product.created_at,
    updatedAt: product.updated_at,
  }));
};
