/* eslint-disable @typescript-eslint/ban-types */
import { restaurantResponseParse } from '@/utils/restaurant';
import { supplierResponseParse } from '@/utils/supplier';

export const orderProductParse = (product: {
  allergens: [];
  available?: boolean;
  brand: string;
  code?: string;
  created_at: string;
  created_by_restaurant?: number;
  currency_code: string;
  formatted_price: Product['formatted_price'];
  id: number;
  name: string;
  order_cutoff_times: {};
  picture_url?: string;
  presentation?: string;
  price: string;
  product_category_id: number;
  product_category: any;
  product_thumbnail: {
    original_url: string;
    medium_url: string;
    thumb_url: string;
  };
  replacement_products?: string;
  seasonality_end_date?: string;
  seasonality_start_date?: string;
  sku: string;
  stock?: number;
  supplier_id: number;
  unit_name: string;
  unit: string;
  updated_at: string;
  uploaded_by_csv_at?: string;
}) => ({
  allergens: [],
  available: product.available,
  brand: product.brand,
  code: product.code,
  created_by_restaurant: product.created_by_restaurant,
  createdAt: product.created_at,
  currencyCode: product.currency_code,
  formatted_price: product.formatted_price,
  id: product.id,
  name: product.name,
  orderCutoffTimes: product.order_cutoff_times,
  pictureUrl: product.picture_url,
  presentation: product.presentation,
  price: product.price,
  product_category: product.product_category,
  product_thumbnail: product.product_thumbnail,
  productCategoryId: product.product_category_id,
  productCategoryName: product.product_category?.name,
  replacementProducts: product.replacement_products,
  seasonalityEndDate: product.seasonality_end_date,
  seasonalityStartDate: product.seasonality_start_date,
  sku: product.sku,
  stock: product.stock,
  supplierId: product.supplier_id,
  unit: product.unit,
  unitName: product.unit_name,
  updatedAt: product.updated_at,
  uploadedByCsvAt: product.uploaded_by_csv_at,
});

export const orderLinesParse = (
  orderLines: Array<{
    created_at: string;
    id: number;
    order_id: number;
    product?: Product;
    product_id: number;
    quantity: number;
    updated_at: string;
  }>,
) => {
  return (orderLines || []).map(line => ({
    createdAt: line.created_at,
    id: line.id,
    orderId: line.order_id,
    product: line.product ? orderProductParse(line.product) : undefined,
    productId: line.product_id,
    quantity: line.quantity,
    updatedAt: line.updated_at,
  }));
};

export const restaurantUserParse = (restaurantUser: {
  __restaurant_signed_in_devices__: [];
  auth0_id: string;
  created_at: string;
  deletedAt?: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  phone_number: string;
  restaurant_id: number;
  role: string;
  updated_at: string;
}) => ({
  createdAt: restaurantUser.created_at,
  email: restaurantUser.email,
  firstName: restaurantUser.first_name,
  id: restaurantUser.id,
  lastName: restaurantUser.last_name,
  phoneNumber: restaurantUser.phone_number,
  restaurantId: restaurantUser.restaurant_id,
  role: restaurantUser.role,
  updatedAt: restaurantUser.updated_at,
});

export const getOrdersResponseParse = (response: BDGetOrder[]): Order[] => {
  // @ts-ignore
  return (response || []).map(order => ({
    createdAt: order.created_at,
    deliveryDate: order.delivery_date,
    deliveryInstructions: order.delivery_instructions,
    id: order.id,
    newDeliveryAddress: order.new_delivery_address,
    orderEvents: order.order_events,
    orderLines: orderLinesParse(order.order_lines),
    reference: order.reference,
    restaurantId: order.restaurant_id,
    restaurant: restaurantResponseParse([order.__restaurant__])[0],
    supplier: supplierResponseParse(order.__supplier__),
    restaurantUserId: order.restaurant_user_id,
    sendbirdChannelUrl: order.sendbird_channel_url,
    status: order.status,
    supplierId: order.supplier_id,
    updatedAt: order.updated_at,
  }));
};

export const getOrderResponseParse = (
  response: BDGetOrder,
): {
  restaurantUserId: number;
  supplierId: number;
  orderEvents: [];
  newDeliveryAddress: string;
  restaurantId: number;
  deliveryInstructions: string;
  sendbirdChannelUrl: string;
  reference: string;
  createdAt: string;
  orderLines: Array<{
    createdAt: string;
    quantity: number;
    productId: number;
    orderId: number;
    id: number;
    updatedAt: string;
  }>;
  id: number;
  deliveryDate: string;
  status: string;
  updatedAt: string;
  createdBy: string;
  restaurantName: string;
  restaurantAddress: string;
  restaurantPreciseAddress: string;
  supplierName: string;
} => {
  return {
    createdAt: response.created_at,
    deliveryDate: response.delivery_date,
    deliveryInstructions: response.delivery_instructions,
    id: response.id,
    newDeliveryAddress: response.new_delivery_address,
    orderEvents: response.order_events,
    orderLines: orderLinesParse(response.order_lines),
    reference: response.reference,
    restaurantId: response.restaurant_id,
    restaurantUserId: response.restaurant_user_id,
    sendbirdChannelUrl: response.sendbird_channel_url,
    status: response.status,
    supplierId: response.supplier_id,
    updatedAt: response.updated_at,
    createdBy: response.created_by,
    restaurantName: response.restaurant_name,
    restaurantAddress: response.restaurant_address,
    restaurantPreciseAddress: response.restaurant_precise_address,
    supplierName: response.supplier_name,
  };
};

export const dbOrderLinesParse = (orderLines: OrderLines): DBOrderLines => {
  return (orderLines || []).map(ol => ({
    created_at: ol.createdAt,
    id: ol.id,
    order_id: ol.orderId,
    product_id: ol.productId,
    quantity: ol.quantity,
    updated_at: ol.updatedAt,
  }));
};
