import { productsResponseParse } from '@/utils/product';
import { restaurantResponseParse } from '@/utils/restaurant';

export const supplierResponseParse = (response: DBSupplier): Supplier | null => {
  if (!response) {
    return null;
  }
  return {
    id: response.id,
    name: response.name,
    address: response.address,
    referredBy: response.referred_by,
    registrationChannel: response.registration_channel,
    businessLocationType: response.business_location_type,
    referralMedium: response.referral_medium,
    hasOfficialCatalog: response.has_official_catalog,
    contactPhone: response.contact_phone,
    contactEmail: response.contact_email,
    currency: response.currency,
    verified: response.verified,
    isAvailableForChat: response.is_available_for_chat,
    showsInPublicListings: response.shows_in_public_listings,
    minimumOrderAmount: response.minimum_order_amount,
    supplierChainId: response.supplier_chain_id,
    products: productsResponseParse((response.products as any) || []),
    supplierUsers: response.supplier_users,
    restaurants: restaurantResponseParse((response.restaurants as any) || []),
    orders: response.orders,
    supplierUserInvitations: response.supplier_user_invitations,
    supplierCategories: response.supplier_categories,
    mainSupplierCategoryId: response.main_supplier_category_id,
    supplierChain: response.supplier_chain,
    initialCatalogPictures: response.initial_catalog_pictures,
    orderCutoffTimes: response.order_cutoff_times,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    pictureUrl: response.picture_url,
  };
};
